var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("BackNav", {
        on: {
          click: function ($event) {
            return _vm.$emit("back")
          },
        },
      }),
      _c("p", { staticClass: "text-lg font-bold text-dark" }, [
        _vm._v("Pay now"),
      ]),
      _c(
        "div",
        {
          staticClass:
            "p-4 my-6 w-full rounded-xl flex items-center justify-between bg-lightPurple-3",
        },
        [
          _c("div", { staticClass: "flex items-center" }, [
            _c("img", {
              staticClass: "h-8 w-8 mr-4",
              attrs: {
                src: "https://res.cloudinary.com/zillaafrica/image/upload/v1631281213/customer/Group_12946_yr3hik.svg",
                alt: "",
              },
            }),
            _c("p", { staticClass: "font-semibole text-black text-lg" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.type === "repayment"
                      ? _vm.singleScheduleData.purchase.orderTitle
                      : _vm.singleScheduleData.orderTitle
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("p", [
            _vm._v(
              " " +
                _vm._s(
                  _vm._f("formatMoney")(_vm.singleScheduleData.amountOwed)
                ) +
                " "
            ),
          ]),
        ]
      ),
      _c(
        "div",
        [
          _c("Select", {
            staticClass: "mt-10 select",
            attrs: {
              placeholder: "How do you want to pay",
              options: _vm.options,
              reduce: function (type) {
                return type.value
              },
            },
            model: {
              value: _vm.payNow.repaymentMethod,
              callback: function ($$v) {
                _vm.$set(_vm.payNow, "repaymentMethod", $$v)
              },
              expression: "payNow.repaymentMethod",
            },
          }),
          _vm.payNow.repaymentMethod === "CARD"
            ? _c(
                "div",
                { staticClass: "mt-10" },
                [
                  _c("CardSelect", {
                    model: {
                      value: _vm.payNow.tokenizedCardId,
                      callback: function ($$v) {
                        _vm.$set(_vm.payNow, "tokenizedCardId", $$v)
                      },
                      expression: "payNow.tokenizedCardId",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("Button", {
            staticClass: "mt-16",
            attrs: {
              text: "Complete payment",
              width: "w-full",
              loading: _vm.loading,
              disabled: _vm.payNow.repaymentMethod === "",
            },
            on: { click: _vm.payRepayment },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }