<template>
  <div>
    <BackNav @click="$emit('back')" />
    <p class="text-lg font-bold text-dark">Pay now</p>
    <div class="p-4 my-6 w-full rounded-xl flex items-center justify-between bg-lightPurple-3">
      <div class="flex items-center">
        <img class="h-8 w-8 mr-4" src="https://res.cloudinary.com/zillaafrica/image/upload/v1631281213/customer/Group_12946_yr3hik.svg" alt="" />
        <p class="font-semibole text-black text-lg">
          {{ type === "repayment" ? singleScheduleData.purchase.orderTitle : singleScheduleData.orderTitle }}
        </p>
      </div>
      <p>
        {{ singleScheduleData.amountOwed | formatMoney }}
      </p>
    </div>
    <div>
      <Select
        placeholder="How do you want to pay"
        class="mt-10 select"
        :options="options"
        :reduce="(type) => type.value"
        v-model="payNow.repaymentMethod"
      />
      <div class="mt-10" v-if="payNow.repaymentMethod === 'CARD'">
        <CardSelect v-model="payNow.tokenizedCardId" />
      </div>

      <Button
        class="mt-16"
        text="Complete payment"
        width="w-full"
        :loading="loading"
        @click="payRepayment"
        :disabled="payNow.repaymentMethod === ''"
      />
    </div>
  </div>
</template>
<script>
  import { mapActions, mapState } from "vuex";
  import { PAY_NOW, PAY_NOW_FROM_PURCHASE } from "@/utils/api/repayments";
  import { BackNav } from "@/UI/Navigation";
  import { Button } from "@/UI/Button";
  import CardSelect from "@/UI/CardSelect";
  import { Select } from "@/UI/Input";

  export default {
    components: {
      BackNav,
      Button,
      Select,
      CardSelect,
    },
    props: {
      singleScheduleData: {
        type: Object,
        default: () => {},
        required: true,
      },
      type: {
        type: String,
        default: "repayment",
        required: false,
      },
    },
    data: () => ({
      options: [
        { label: "Card", value: "CARD" },
        { label: "Wallet", value: "INSTA_WALLET" },
      ],
      payNow: {
        repaymentMethod: "INSTA_WALLET",
        tokenizedCardId: "",
      },
      loading: false,
    }),
    computed: {
      ...mapState({
        userCards: (state) => state.dashboard.userCards,
      }),
    },
    methods: {
      ...mapActions("dashboard", ["getCards"]),
      ...mapActions("notification", ["showAlert"]),
      payRepayment() {
        this.loading = true;
        const endPoint = this.type === "repayment" ? PAY_NOW : PAY_NOW_FROM_PURCHASE;
        const ID = this.type === "repayment" ? this.singleScheduleData.id : this.singleScheduleData.loanId;
        endPoint(ID, this.payNow)
          .then(() => {
            this.showAlert({
              display: true,
              description: "Your repayment was successful",
              type: "success",
              // title: "Validation Error",
            });
            this.loading = false;
            this.$emit("completed");
          })
          .catch(() => {
            this.loading = false;
          });
      },
    },
  };
</script>
